import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarEmpresaParceira = async (payload, page) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_EMPRESA_PARCEIRA + `?page=${page}`, payload);
    return data
}

export const criarEmpresaParceira = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_EMPRESA_PARCEIRA, payload);
    return data
}

export const exibirEmpresaParceira = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_EMPRESA_PARCEIRA + `/${id}`);
    return data
}

export const editarEmpresaParceira = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_EMPRESA_PARCEIRA + `/${id}`, payload);
    return data
}

export const excluirEmpresaParceira = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_EMPRESA_PARCEIRA + `/${id}`);
    return data
}

export const listarEmpresaParceira = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_EMPRESA_PARCEIRA);
    return data
}