<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="4">
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome"
            outlined
            dense
            :clearable="true"
            label="Nome Fantasia"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.cnpj"
            placeholder="Digite o CNPJ"
            outlined
            dense
            :clearable="true"
            label="CNPJ"
            required
            :rules="rules.genericRules"
            v-mask="'##.###.###/####-##'"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.razaoSocial"
            placeholder="Digite a Razão Social"
            outlined
            dense
            :clearable="true"
            label="Razão Social"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-textarea
            outlined
            label="Descrição"
            v-model="formData.descricao"
            :clearable="true"
            rows="3"
            required
            :rules="rules.genericRules"
          ></v-textarea>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="formData.endereco"
            placeholder="Digite o endereço"
            outlined
            dense
            :clearable="true"
            label="Endereço"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.bairro"
            placeholder="Digite o bairro"
            outlined
            dense
            :clearable="true"
            label="Bairro"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-text-field
            v-model="formData.cidade"
            placeholder="Digite a cidade"
            outlined
            dense
            :clearable="true"
            label="Cidade"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
          <v-row>
            <v-col cols="7">
              <v-autocomplete
                v-model="formData.estado"
                :items="estados"
                hide-no-data
                hide-selected
                item-text="text"
                item-value="value"
                placeholder="Digite o estado"
                outlined
                dense
                :clearable="true"
                label="Estado"
                required
                :rules="rules.selectRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="formData.cep"
                placeholder="Digite o CEP"
                outlined
                dense
                :clearable="true"
                label="CEP"
                required
                :rules="rules.selectRules"
                v-mask="'#####-###'"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-switch
            v-model="formData.ativo"
            label="Ativo"
            inset
            :hide-details="true"
            class="mt-0 pa-0 mb-4"
            color="cyan"
          ></v-switch>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar Empresa" : "Criar Empresa" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
import { ESTADOS_LIST } from "@/utils/constants.js";
export default {
  name: "EmpresaParceiraFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    formData: {
      nome: null,
      cnpj: null,
      razaoSocial: null,
      descricao: null,
      endereco: null,
      bairro: null,
      cidade: null,
      estado: null,
      cep: null,
      ativo: false,
    },
    estados: ESTADOS_LIST,
    rules: rules,
    valid: true,
    isEdit: false,
  }),
  created() {
    if (this.$route.name === "EmpresaParceiraEditar") {
      this.formData = this.empresa;
      this.isEdit = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
